<template>
  <div class="checkout-container">
    <v-container
      class="checkout"
      :class="{ 'w-90': $vuetify.breakpoint.lgAndUp }"
    >
      <category-block
        :target="category"
        :container="false"
        position="position1"
        class="category-block category-block-1"
      />
      <div class="checkout-title">
        <CategoryTitle
          :category="category"
          :showOnMobile="true"
          :showDescription="true"
          :addContainer="false"
        />
      </div>

      <ResponseMessage :response="response" class="px-2" />
      <v-row>
        <v-col cols="12" md="8" lg="8">
          <v-row justify="space-between" class="mb-3">
            <v-col cols="12" class="px-0">
              <div class="px-3" v-if="$vuetify.breakpoint.smAndDown">
                <CartInfoAddressCard
                  :shippingAddress="cart.shippingAddress"
                  :editable="editableAddress"
                  @edit="openAddressSelector"
                />
                <CartInfoTimeslotCard
                  :shippingAddress="cart.shippingAddress"
                  :timeslot="cart.timeslot"
                  :editable="editableTimeslot"
                  @edit="openTimeslotSelector"
                  class="cart-info-timeslot-card"
                  :value="timeslot"
                />
                <div class="gross-total-row text-right mt-0">
                  <span class="text-h5"
                    >{{ $t("checkout.summary.total") }}:
                    <span class="gross-total">
                      {{ $n(cart.grossTotal, "currency") }}
                    </span>
                  </span>
                </div>
                <v-btn
                  color="primary"
                  class="rounded-pill w-100 my-3"
                  large
                  depressed
                  :disabled="
                    profileLevel < minProfileLevel || cart.totalItems == 0
                  "
                  :loading="loading"
                  @click="goToPayment"
                >
                  <span>{{ $t("checkout.goToPaymentBtn") }}</span>
                </v-btn>
              </div>
              <CartItemList
                id="checkout-items-list"
                class="checkout-items-list"
                :full="true"
              />
              <v-alert
                v-if="error"
                type="error"
                border="left"
                transition="v-fab-transition"
              >
                {{ error }}
              </v-alert>
              <div class="checkout-note pa-3">{{ $t("checkout.note") }}</div>
            </v-col>
            <v-col cols="12">
              <div
                class="checkout-actions d-flex justify-space-between align-center flex-column flex-md-row"
              >
                <div
                  class="d-flex flex-column flex-md-row mb-2 mb-md-0 w-100 w-sm-auto justify-space-between justify-md-start"
                >
                  <v-btn
                    color="default"
                    class="rounded-pill"
                    large
                    text
                    depressed
                    @click="emptyCartConfirm"
                  >
                    <span>{{ $t("checkout.emptyCartBtn") }}</span>
                  </v-btn>
                </div>

                <div
                  class="d-flex flex-column flex-md-row justify-space-between justify-md-end w-100 w-sm-auto"
                >
                  <v-btn
                    class="add-all-to-list mr-0 mr-md-2 mb-2 mb-md-0 white rounded-pill default--text"
                    large
                    depressed
                    outlined
                    @click="addAllToFavorites"
                  >
                    <span>{{ $t("checkout.addToListBtn") }}</span>
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="rounded-pill hidden-sm-and-down"
                    large
                    depressed
                    :disabled="
                      profileLevel < minProfileLevel || cart.totalItems == 0
                    "
                    :loading="loading"
                    @click="goToPayment"
                  >
                    <span>{{ $t("checkout.goToPaymentBtn") }}</span>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4" lg="4" class="d-flex flex-column">
          <!-- check if disclaimer is accepted -->
          <CheckoutCompleteProfile
            class="mb-5 order-1"
            v-if="profileLevel < minProfileLevel"
          />

          <div v-if="$vuetify.breakpoint.mdAndUp" class="order-2">
            <CartInfoAddressCard
              :shippingAddress="cart.shippingAddress"
              :editable="editableAddress"
              @edit="openAddressSelector"
            />
            <CartInfoTimeslotCard
              :shippingAddress="cart.shippingAddress"
              :timeslot="cart.timeslot"
              :editable="editableTimeslot"
              @edit="openTimeslotSelector"
              class="cart-info-timeslot-card"
              :value="timeslot"
            />
          </div>

          <div class="gifts order-4 order-md-3">
            <GiftCode
              :giftCertificates="giftCertificates"
              class="mb-3 py-1"
              @add="loadGiftCertificates"
              @remove="loadGiftCertificates"
              @reloadVllGiftVouchers="() => renderKey++"
            />
            <gift-list
              v-if="giftCertificates.length > 0"
              :mode="'checkout'"
              :title="$t('checkout.giftCodeSubtitle')"
              :giftList="giftCertificates"
              @reload="loadGiftCertificates"
            />
          </div>

          <div
            class="order-5 order-md-4"
            v-if="
              giftCertificatesReceived && giftCertificatesReceived.length > 0
            "
          >
            <div
              v-for="gift in giftCertificatesReceived"
              :key="gift.userGiftCertificateId"
              class="gift-certificate-received d-flex align-center py-4"
            >
              <v-avatar color="secondary" class="mr-4" size="48" rounded>
                <span class="white--text text-h5">
                  {{ gift.amount }}&euro;
                </span>
              </v-avatar>
              <div class="gift-certificate-content flex-grow-1">
                <span class="text-body-2 font-weight-semibold">
                  {{ gift.description }}
                </span>
                <div
                  v-if="gift.endDate"
                  class="expiration-date lighten-3"
                  v-html="
                    `<span class='font-weight-semibold text-uppercase'>${$t(
                      'checkout.expires'
                    )}</span>: ${$t('benefits.endDate')} 
                ${$dayjs(gift.endDate).format('DD/MM')}`
                  "
                ></div>
              </div>
              <v-btn
                color="primary"
                depressed
                small
                class="rounded-pill"
                @click.stop="addUserGift(gift.userGiftCertificateId)"
              >
                {{ $t("benefits.useBtn") }}
              </v-btn>
            </div>
          </div>

          <CheckoutSummary
            class="order-6 order-md-5"
            :orderCart="cart"
            @removeGiftCode="removeGiftV2"
            :key="renderKey"
          />

          <v-btn
            color="primary"
            class="rounded-pill w-100 order-7 order-md-6"
            large
            depressed
            :disabled="profileLevel < minProfileLevel || cart.totalItems == 0"
            :loading="loading"
            @click="goToPayment"
          >
            <span>{{ $t("checkout.goToPaymentBtn") }}</span>
          </v-btn>

          <div class="category-block-side-container order-3 order-md-7 mt-3">
            <category-block
              :target="category"
              :container="false"
              position="position3"
              class="category-block category-block-3"
            />
            <category-block
              :target="category"
              :container="false"
              position="position4"
              class="category-block category-block-4"
            />
            <category-block
              :target="category"
              :container="false"
              position="position5"
              class="category-block category-block-5"
            />
          </div>
        </v-col>
      </v-row>
      <category-block
        :target="category"
        :container="false"
        position="position2"
        class="category-block category-block-2"
      />
    </v-container>

    <div class="white mb-6" v-if="cart.cartItems[0]">
      <v-container class="pa-0">
        <ProductListSlider
          :layout="29"
          :userId="cart.user.userId"
          :warehouseId="cart.warehouse.warehouseId"
          :timeslotId="cart.timeslot.date + cart.timeslot.timeslotId"
          :limit="24"
          :key="cart.cartItems[0].product.productId + '_3'"
          paginationClass="often-bought-together"
          :showArrows="!$vuetify.breakpoint.xsOnly"
          :showBullets="false"
          position="product_variations"
          v-intersect.once="handleView"
          @selectProduct="handleClick"
        >
          <h2 class="my-3">
            {{ $t("product.oftenBoughtTogether") }}
          </h2>
        </ProductListSlider>
      </v-container>
    </div>
  </div>
</template>
<style lang="scss">
.checkout {
  .bubble-checkout,
  .bubble-checkout-mxn {
    color: #e11d23;
  }
  .gift-certificate-received {
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 12px;
    // border: 1px solid var(--v-grey-lighten1) !important;
    padding: 8px;
  }
  .add-all-to-list {
    border: 1px solid var(--v-grey-lighten1) !important;
  }
  .cart-info-timeslot-card {
    .value {
      text-transform: uppercase;
    }
  }
  .checkout-item-list {
    background: transparent;
    .v-subheader {
      background: transparent;
    }
  }

  .v-list {
    padding: 0;
  }
  // .checkout-note {
  //   border-top: 8px solid $white;
  // }
  .accept-replacement {
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
    column-gap: 10px;
    padding: 10px 5px;
    border: 1px solid $primary;
    background-color: #ffffff !important;
    border-radius: 4px;
    p {
      font-size: 14px;
      font-weight: 600;
    }
    .v-input--checkbox .v-label {
      font-weight: normal;
    }
    .v-input--selection-controls {
      margin-top: 0px;
    }
  }
  .category-block-3,
  .category-block-4,
  .category-block-5 {
    .product-list-slider.slider {
      padding: 8px;
    }
  }
}
</style>
<script>
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import CheckoutCompleteProfile from "@/components/cart/CheckoutCompleteProfile.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ListSelector from "@/components/lists/ListSelector.vue";
import CartItemList from "@/components/cart/CartItemList.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";

import AnalyticsService from "~/service/analyticsService";
import ListService from "~/service/listService";
import RegistrationService from "~/service/userService";
import GiftProductSelector from "@/components/gift/GiftProductSelector.vue";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";

import { forEachCartItem } from "~/service/ebsn.js";
import { mapActions, mapGetters } from "vuex";
import get from "lodash/get";
import customService from "@/service/customService";

//import GiftList from "@/components/gift/GiftList.vue";
import GiftCode from "@/components/gift/GiftCode.vue";
import analyticsService from "~/service/analyticsService";
export default {
  name: "Checkout",
  mixins: [login, cartInfo, categoryMixins],
  components: {
    CategoryTitle,
    CheckoutSummary,
    CheckoutCompleteProfile,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    CartItemList,
    ResponseMessage,
    //GiftList,
    GiftCode,
    // CategoryBlock,
    ProductListSlider
  },
  data() {
    return {
      renderKey: 1,
      error: null,
      loading: false,
      response: {},
      giftCertificates: [],
      emailKey: 1,
      packages: true,
      selectedOptions: {},
      iosMobile: false,
      showPersonalCoupon: false,
      dialogInstance: null,
      minProfileLevel: global.config.minProfileLevel
    };
  },
  computed: {
    ...mapGetters({
      profileLevel: "cart/profileLevel"
    }),
    editableAddress() {
      return !(this.cart.cartStatusId == 7);
    },
    editableTimeslot() {
      return !(this.$route.name == "Payment");
    },
    giftCertificatesReceived() {
      return this.cart.user?.giftCertificatesReceived;
    }
  },

  methods: {
    get: get,
    ...mapActions({
      lockCart: "cart/lockCart",
      emptyCart: "cart/emptyCart",
      removeGiftCode: "cart/removeGiftCode",
      loadCart: "cart/loadCart",
      getCart: "cart/getCart",
      addGiftCode: "cart/addGiftCode",
      setCartInfo: "cart/setCartInfo"
    }),
    async addUserGift(userGiftCertificateId) {
      await customService.customAddCode(userGiftCertificateId);
      await this.getCart();
      await this.loadGiftCertificates();
      // await this.addGiftCode(userGiftCertificateId);
    },
    sendActivationMail() {
      this.response = {};
      let _this = this;
      RegistrationService.registrationResendEmail(this.cart.user.email).then(
        function(data) {
          _this.response = data.response;
        },
        function(response) {
          _this.response = response;
        }
      );
    },
    async loadGiftCertificates() {
      //this.autoAssignedGifts = await userService.listGiftCertificate("auto");

      let giftCertificates = await RegistrationService.listGiftCertificate(
        "list"
      );
      var manualGiftCertificates = await RegistrationService.listGiftCertificate(
        "manual"
      );

      this.giftCertificates = [
        ...giftCertificates,
        ...manualGiftCertificates
      ].filter(giftCertificate => {
        // if (giftCertificate.giftAdjustmentType.giftAdjustmentTypeId == 21) {
        //   //hide refund
        //   return false;
        // }
        //check user gift
        let hasUserGift = [];
        if (giftCertificate.userGiftCertificates?.length > 0) {
          hasUserGift = giftCertificate.userGiftCertificates.filter(
            userGiftCertificate => {
              return !userGiftCertificate.orderId;
            }
          );
        }
        return !!giftCertificate.giftCode || hasUserGift.length > 0;
      });
      this.giftCertificates = this.giftCertificates.filter(
        gift => !gift?.userGiftCertificates[0]?.descrExtra
      );
    },

    async addAllToFavorites() {
      let _this = this;
      let res = await _this.$dialog.show(ListSelector, {
        waitForResult: true,
        fullscreen: _this.$vuetify.breakpoint.smAndDown,
        width: 300
      });
      if (res) {
        let items = [];
        forEachCartItem(_this.cart, function(item) {
          items.push(item);
        });
        await ListService.addProductsToList(res.listId, items);
      }
    },
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
          AnalyticsService.cartEvent("emptyCart", this.cart.cartItems);
        }
      }
    },
    async goToPayment() {
      let _this = this;
      _this.loading = true;
      try {
        if (await this.needLogin("addtocart")) {
          if (await this.needAddress()) {
            if (await this.needTimeslot()) {
              await _this.lockCart();
              _this.$router.push({
                name: "Payment"
              });
            }
          }
        }
      } catch (err) {
        await this.getCart();
        await this.needTimeslot();
        _this.error = err.message;
      } finally {
        _this.loading = false;
      }
    },
    async showPersonalCouponDialog() {
      this.dialogInstance = await this.$dialog.show(GiftProductSelector, {
        title: "Scegli ii proodtti a cui appliicare i tuoi bolloni",
        waitForResult: false,
        fullscreen: this.$vuetify.breakpoint.smAndDown
      });
      const result = await this.dialogInstance.wait();
      if (result) {
        await this.loadCart();
      }
      return result;
    },
    checkOsx() {
      var userAgent = [];
      var version = [];
      var versionNumber = 0;
      if (navigator.userAgent.indexOf("iPhone") > -1) {
        userAgent = navigator.userAgent.split(" ");
        userAgent.filter(ua => {
          if (ua.indexOf("Version/") > -1) {
            version = ua.split("/");
          }
        });
        versionNumber = parseFloat(version[1]);
        if (version[0] == "Version") {
          if (versionNumber < 15) {
            this.iosMobile = true;
          } else {
            this.iosMobile = false;
          }
        } else {
          this.iosMobile = false;
        }
      }
    },
    async removeGift(gift) {
      await this.removeGiftCode(gift);
      this.loadGiftCertificates();
    },
    async removeGiftV2(gift) {
      await customService.customRemoveCode(gift);
      this.loadGiftCertificates();
    },
    handleView(entries, observer, isIntersecting) {
      if (isIntersecting) {
        analyticsService.viewProducts(this.products, "Checkout Product Slider");
      }
    },

    handleClick(payload) {
      analyticsService.clickProduct(
        payload.product,
        "Checkout Product Slider",
        payload.index
      );
    }
  },
  async mounted() {
    // if (!this.cart.user.profile.confirmed) {
    //   this.sendActivationMail();
    // }
    await this.getCart();
    global.EventBus.$on("resetAlertMessage", () => {
      if (this.error) {
        this.error = false;
      }
    });
    AnalyticsService.viewProducts(this.cart.cartItems, this.itemListName, 0);
    // AnalyticsService.beginCheckout(this.cart);
    AnalyticsService.viewCart(this.cart);
    this.checkOsx();
    await this.loadGiftCertificates();
  }
};
</script>
