<template>
  <v-row
    no-gutters
    align="center"
    justify="start"
    class="certificate py-2 px-0 default--text"
  >
    <!-- <v-col cols="1">
      <v-img v-if="src" :src="src" :alt="`Coupon ${name}`" class="rounded-sm" />
      <div
        v-else
        class="certificate-card d-flex flex-column justify-space-around align-center"
        @click.prevent.stop="handleClick"
        outlined
      >
        <span>%</span>
      </div>
    </v-col> -->
    <v-col cols="12" sm="8" md="12" lg="8" class="default--text">
      <div class="d-flex align-center">
        <div
          v-if="giftCertificate.userGiftCertificates"
          class="giftCodeLogo flex-shrink-0 mr-2"
        >
          {{ giftCertificate.userGiftCertificates[0].amount }}€
        </div>
        <div>
          <div
            class=" text-body-2 font-weight-semibold"
            v-html="description"
          ></div>
          <div class="text-caption">
            {{
              `${$t("checkout.expires")} ${$dayjs(
                giftCertificate.endDate
              ).format("D MMMM YYYY")}`
            }}
          </div>
        </div>
      </div>
    </v-col>
    <v-col
      cols="12"
      sm="4"
      md="12"
      lg="4"
      class="gift-action d-flex justify-end"
    >
      <v-btn
        v-if="
          userGiftCertificateId != null && giftCertificate.cartId != cart.cartId
        "
        depressed
        outlined
        color="primary"
        min-width="80"
        @click="remove(userGiftCertificateId)"
      >
        {{ $t("checkout.giftCodeRemove") }}
      </v-btn>
      <v-btn v-else depressed color="primary" @click="handleAdd()">
        {{ $t("checkout.giftCodeUse") }}
      </v-btn>
    </v-col>
    <!-- DESCRIPTION FOR MOBILE DEVICES -->
    <!-- <v-col cols="10" class="d-flex d-sm-none offset-2" v-html="description">
    </v-col> -->
  </v-row>
</template>
<style lang="scss" scoped>
.giftCodeLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: var(--v-grey-lighten2);
  font-size: 20px;
  font-weight: 600;
  border-radius: 8px;
}
.gift-action {
  .v-btn {
    max-width: 120px;
  }
}
</style>
<script>
import giftCertificate from "@/components/gift/giftCertificate";
import { mapActions } from "vuex";
export default {
  name: "GiftRow",
  mixins: [giftCertificate],
  methods: {
    ...mapActions({
      addGiftCode: "cart/sendGiftCode"
    }),
    async sendGiftCode(gift) {
      await this.addGiftCode(gift);
      this.giftCode = "";
      this.$emit("add");
    },
    async handleAdd() {
      if (this.giftCertificate.giftCode) {
        await this.sendGiftCode(this.giftCertificate.giftCode);
      } else {
        await this.add();
      }
      this.$emit("add");

      // const userGiftCertificatesWithOrderId =
      //   this.giftCertificate?.userGiftCertificates?.filter(
      //     ugc => ugc.orderId !== null
      //   ) || [];
      // console.log(
      //   "userGiftCertificatesWithOrderId",
      //   userGiftCertificatesWithOrderId,
      //   this.giftCertificate
      // );
      // if (
      //   userGiftCertificatesWithOrderId.length ===
      //     this.giftCertificate.userGiftCertificates &&
      //   this.giftCertificate.userGiftCertificates.length
      // ) {
      //   this.sendGiftCode(this.giftCertificate.giftCode);
      // } else {
      //   this.add();
      // }
    }
  }
};
</script>
